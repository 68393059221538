<template>
  <div>
    <el-upload
      class="upload-demo"
      action
      :on-remove="handleRemove"
      multiple
      :show-file-list="isShowList"
      :before-upload="beforeUpload"
      :on-success="successUpload"
      :http-request="upload"
    >
      <el-button type="info">上传文件</el-button>
    </el-upload>
  </div>
</template>
<script>
import { uploadFile } from "@/api/common";
export default {
  props: {
    isShowList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileArray: [],
      fileKey: ""
    };
  },
  methods: {
    //上传成功文件
    successUpload(response, file, fileList) {
      this.$emit("fileSucee", file.name);
    },
    handleRemove(file, fileList) {},
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const res = await uploadFile(form);
      if (res.code == 200) {
        this.fileKey = res.data.fileAddr[0];
        this.$emit("upFileKey", this.fileKey);
      }
    },

    beforeUpload(file) {
      // let FileExt = file.name.replace(/.+\./, '')
      // let flag = ['doc', 'docx', 'xls', 'xlsx'].includes(FileExt)
      // const isLt5M = file.size / 1024 / 1024 < 5
      // if (!flag) this.$message.error('文件格式错误请重新上传!')
      // if (!isLt5M) this.$message.error('上传的文件大小不能超过5MB!')
      // return flag && isLt5M
    }
  }
};
</script>
<style lang="less" scoped>
.iconjr-icon-link {
  font-size: 12px;
}
.the-active {
  /deep/ .el-button {
    color: #4da75b !important;
    padding: 11px 10px;
  }
  i {
    color: #4da75b !important;
  }
}
/deep/ .el-button {
  padding: 11px 10px;
}
</style>

<!-- 日期搜索框 -->
<template>
  <div class="myDate">
    <el-select
      v-model="yearsModel"
      @change="dateChange(1)"
      placeholder="请选择"
      class="middle-select"
    >
      <el-option
        v-for="item in years"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-select
      v-model="monthsModel"
      @change="dateChange(2)"
      placeholder="请选择"
      class="middle-select"
    >
      <el-option
        v-for="item in months"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-select
      v-if="isShowDay"
      v-model="daysModel"
      @change="dateChange(3)"
      placeholder="请选择"
      class="middle-select"
    >
      <el-option
        v-for="item in days"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    parentYear: {
      type: Number,
    },
    parentMonth: {
      type: Number,
    },
    parentDay: {
      type: Number,
    },
    isShowDay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      yearsModel: null,
      years: [],
      monthsModel: null,
      months: [],
      daysModel: null,
      days: [],
    };
  },

  watch: {
    parentYear: {
      handler: function (newVal) {
        if (newVal) {
          this.yearsModel = newVal;
        }
      },
      immediate: true,
    },
    parentMonth: {
      handler: function (newVal) {
        if (newVal) {
          this.monthsModel = newVal;

          this.initSelectDay(this.yearsModel, newVal);
        }
      },
      immediate: true,
    },
    parentDay: {
      handler: function (newVal) {
        if (newVal) {
          this.daysModel = newVal;
        }
      },
      immediate: true,
    },
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      let myDate = new Date();
      let year = myDate.getFullYear(); //获取当前年
      let month = myDate.getMonth() + 1; //获取当前月
      let day = myDate.getDate(); //获取当前日
      this.initSelectYear(year);
      this.initSelectMonth();
      this.initSelectDay(year, month);
    },
    initSelectYear(year) {
      // this.years = [{ label: "不限", value: 0 }];
      for (let i = 0; i < 30; i++) {
        this.years.push({ value: year - i, label: year - i + "年" });
      }
    },
    initSelectMonth() {
      // this.months = [{ label: "不限", value: 0 }];
      for (let i = 1; i <= 12; i++) {
        this.months.push({ value: i, label: i + "月" });
      }
    },
    initSelectDay(year, month) {
      var maxDay = this.getMaxDay(year, month);
      // this.days = [{ label: "不限", value: 0 }];
      this.days = [];
      for (var i = 1; i <= maxDay; i++) {
        this.days.push({ value: i, label: i + "日" });
      }
    },
    dateChange(type) {
      if (type == 1 || type == 2) {
        if (this.monthsModel == 0) {
          this.initSelectDay(this.yearsModel, 1);
        } else {
          this.initSelectDay(this.yearsModel, this.monthsModel);
        }
      }

      this.$emit(
        "dateChange",
        this.yearsModel,
        this.monthsModel,
        this.daysModel
      );
    },
    getMaxDay(year, month) {
      var new_year = year; //取当前的年份
      var new_month = month++; //取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) {
        //如果当前大于12月，则年份转到下一年
        new_month -= 12; //月份减
        new_year++; //年份增
      }
      var new_date = new Date(new_year, new_month, 1); //取当年当月中的第一天
      return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate(); //获取当月最后一天日期
    },
  },
};
</script>

<style lang="less" scoped>
.myDate {
  .el-select {
    margin-right: 10px;
  }
}
</style>

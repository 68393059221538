import request from "@/utils/request";

// 国家标准

// 查询
export function countryStandardList(data) {
  return request({
    url: "/system/countryStandard/getNationalStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertCountryStandard(data) {
  return request({
    url: "/system/countryStandard/insertNationalStandard",
    method: "post",
    data
  });
}

// 修改
export function updateCountryStandard(data) {
  return request({
    url: "/system/countryStandard/updateNationalStandard",
    method: "post",
    data
  });
}

// 删除
export function deleteCountryStandard(data) {
  return request({
    url: "/system/countryStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getCountryStandardOne(data) {
  return request({
    url: "/system/countryStandard/getCountryStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importCountryStandard(data) {
  return request({
    url: "/system/countryStandard/importProject",
    method: "post",
    data
  });
}

// 报告上传
export function importCountryReport(data) {
  return request({
    url: "/system/countryStandard/importCountryReport",
    method: "post",
    data
  });
}

// 行业标准

// 一览
export function getBehaviorStandardList(data) {
  return request({
    url: "/system/behaviorStandard/getBehaviorStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertBehaviorStandard(data) {
  return request({
    url: "/system/behaviorStandard/insertBehaviorStandard",
    method: "post",
    data
  });
}

// 修改
export function updateBehaviorStandard(data) {
  return request({
    url: "/system/behaviorStandard/updateBehaviorStandard",
    method: "post",
    data
  });
}

// 删除
export function behaviorStandardDeleteByIdList(data) {
  return request({
    url: "/system/behaviorStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getBehaviorStandardOne(data) {
  return request({
    url: "/system/behaviorStandard/getBehaviorStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importBehaviorStandard(data) {
  return request({
    url: "/system/behaviorStandard/importBehaviorStandard",
    method: "post",
    data
  });
}

// 报告上传
export function importBehaviorReport(data) {
  return request({
    url: "/system/behaviorStandard/importBehaviorReport",
    method: "post",
    data
  });
}

// 地方标准

// 一览
export function getLocalStandardList(data) {
  return request({
    url: "/system/localStandard/getLocalStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertLocalStandard(data) {
  return request({
    url: "/system/localStandard/insertLocalStandard",
    method: "post",
    data
  });
}

// 修改
export function updateLocalStandard(data) {
  return request({
    url: "/system/localStandard/updateLocalStandard",
    method: "post",
    data
  });
}

// 删除
export function localStandardDeleteByIdList(data) {
  return request({
    url: "/system/localStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getLocalStandardOne(data) {
  return request({
    url: "/system/localStandard/getLocalStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importLocalStandard(data) {
  return request({
    url: "/system/localStandard/importLocalStandard",
    method: "post",
    data
  });
}

// 报告上传
export function importLocalSReport(data) {
  return request({
    url: "/system/localStandard/importLocalSReport",
    method: "post",
    data
  });
}

// 团体标准

// 一览
export function getTeamStandardList(data) {
  return request({
    url: "/system/teamStandard/getTeamStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertTeamStandard(data) {
  return request({
    url: "/system/teamStandard/insertTeamStandard",
    method: "post",
    data
  });
}

// 修改
export function updateTeamStandard(data) {
  return request({
    url: "/system/teamStandard/updateTeamStandard",
    method: "post",
    data
  });
}

// 删除
export function teamStandardDeleteByIdList(data) {
  return request({
    url: "/system/teamStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getTeamStandardOne(data) {
  return request({
    url: "/system/teamStandard/getTeamStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importTeamStandard(data) {
  return request({
    url: "/system/teamStandard/importTeamStandard",
    method: "post",
    data
  });
}

// 报告上传
export function importTeamReport(data) {
  return request({
    url: "/system/teamStandard/importTeamReport",
    method: "post",
    data
  });
}

// 企业标准

// 一览
export function getCompanyStandardList(data) {
  return request({
    url: "/system/companyStandard/getCompanyStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertCompanyStandard(data) {
  return request({
    url: "/system/companyStandard/insertCompanyStandard",
    method: "post",
    data
  });
}

// 修改
export function updateCompanyStandard(data) {
  return request({
    url: "/system/companyStandard/updateCompanyStandard",
    method: "post",
    data
  });
}

// 删除
export function companyStandardDeleteByIdList(data) {
  return request({
    url: "/system/companyStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getCompanyStandardOne(data) {
  return request({
    url: "/system/companyStandard/getCompanyStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importCompanyStandard(data) {
  return request({
    url: "/system/companyStandard/importProject",
    method: "post",
    data
  });
}

// 报告上传
export function importCompanyReport(data) {
  return request({
    url: "/system/companyStandard/importCompanyReport",
    method: "post",
    data
  });
}

// 国际标准

// 一览
export function getInternationalStandardList(data) {
  return request({
    url: "/system/internationalStandard/getInternationalStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertInternationalStandard(data) {
  return request({
    url: "/system/internationalStandard/insertInternationalStandard",
    method: "post",
    data
  });
}

// 修改
export function updateInternationalStandard(data) {
  return request({
    url: "/system/internationalStandard/updateInternationalStandard",
    method: "post",
    data
  });
}

// 删除
export function internationalStandardDeleteByIdList(data) {
  return request({
    url: "/system/internationalStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getInternationalStandardOne(data) {
  return request({
    url: "/system/internationalStandard/getInternationalStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importInternationalStandard(data) {
  return request({
    url: "/system/internationalStandard/importInternationalStandard",
    method: "post",
    data
  });
}

// 报告上传
// export function importCompanyReport(data) {
//   return request({
//     url: "/system/teamStandard/importTeamReport",
//     method: "post",
//     data
//   });
// }

// 国外标准

// 一览
export function getForeignStandardList(data) {
  return request({
    url: "/system/foreignStandard/getForeignStandardList",
    method: "post",
    data
  });
}

// 新增
export function insertForeignStandard(data) {
  return request({
    url: "/system/foreignStandard/insertForeignStandard",
    method: "post",
    data
  });
}

// 修改
export function updateForeignStandard(data) {
  return request({
    url: "/system/foreignStandard/updateForeignStandard",
    method: "post",
    data
  });
}

// 删除
export function foreignStandardStandardDeleteByIdList(data) {
  return request({
    url: "/system/foreignStandard/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getForeignStandardOne(data) {
  return request({
    url: "/system/foreignStandard/getForeignStandardOne",
    method: "get",
    params: data
  });
}

// 导入
export function importForeignStandard(data) {
  return request({
    url: "/system/foreignStandard/importForeignStandard",
    method: "post",
    data
  });
}

// 报告上传
// export function importCompanyReport(data) {
//   return request({
//     url: "/system/teamStandard/importTeamReport",
//     method: "post",
//     data
//   });
// }

// 客户企业模块数据一览
export function getClientEnterpriseServiceList(data) {
  return request({
    url: "/system/clientEnterprise/getClientEnterpriseServiceList",
    method: "post",
    data
  });
}

// 单个下载
export function downFile(data) {
  return request({
    url: "/system/internationalStandard/downFile",
    method: "get",
    params: data
  });
}
//场景名称智能查询
export function selectAssociatedScenarios(data) {
  return request({
    url: "/system/scenarioBasic/selectAssociatedScenarios",
    method: "get",
    params: data
  });
}
//企业行为标签智能查询
export function selectAssociated(data) {
  return request({
    url: "/system/scenarioBasic/selectAssociatedBehaviorLabel",
    method: "get",
    params: data
  });
}
//模糊查询赋能标签
export function getEmpoweringLabels(data) {
  return request({
    url: "/system/generationProcess/getEmpoweringLabels",
    method: "get",
    params: data
  });
}
//查询所有的产业链
export function getAllIndustrialChain() {
  return request({
    url: "/system/generationProcess/getAllIndustrialChain",
    method: "get",
   
  });
}
//案例-关联产品查询
export function getProductByName(data) {
  return request({
    url: "/system/Plan/getProductByName",
    method: "get",
    params: data
  });
}
//案例-智参企业智能查询
export function getZcByCompanyName(data) {
  return request({
    url: "/system/Plan/getZcByCompanyName",
    method: "get",
    params: data
  });
}
//生产工艺详情
export function selectById(data) {
  return request({
    url: "/system/generationProcess/selectById",
    method: "get",
    params: data
  });
}
//生产工艺编辑
export function updateGenerationProcess(data) {
  return request({
    url: "/system/generationProcess/updateGenerationProcess",
    method: "post",
    data
  });
}
//生产工艺删除
export function deleteById(data) {
  return request({
    url: "/system/generationProcess/deleteById",
    method: "post",
    data
  });
}
//查询所有的生产工艺数据
export function getAllScenarioProcess(data) {
  return request({
    url: "/system/generationProcess/getAllScenarioProcess",
    method: "get",
    params: data
  });
}

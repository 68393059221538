<template>
  <div>
    <el-radio-group v-model="value">
      <el-radio v-for="item in options" :key="item.codeId" :label="item.codeName">{{item.codeName}}</el-radio>
    </el-radio-group>
  </div>
</template>

<script >
import { getDicRadioButton } from '@/api/common'
export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
    },
    code: {
      // 选中的那一项的key
      type: String,
      require: true,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      value: '',
      options: [],
    }
  },
  watch: {
    code: function (newVal) {
      this.value = newVal
    },

    value: function (newVal) {
      this.$emit('update:code', newVal)
    },
  },

  created() {
    this.getDicData()
  },

  methods: {
    async getDicData() {
      this.options = sessionStorage.getItem(this.typeCode)

      if (!this.options) {
        const { data: res } = await getDicRadioButton({ code: this.typeCode })

        sessionStorage.setItem(this.typeCode, JSON.stringify(res))
      }

      this.options = JSON.parse(sessionStorage.getItem(this.typeCode))

      if (this.code) {
        this.options.forEach((item) => {
          if (item.codeName === this.code) {
            this.value = item.codeName
          }
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>
